<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="info">
        <a-descriptions bordered size="small" :column="1">
          <a-descriptions-item>
            <div slot="label" class="center">组织名称</div>
            <div class="">
              {{ detail.uniqueName }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">组织类型</div>
            <div class="">
              <DataDictFinder dictType="orgType" :dictValue="detail.type" />
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <div slot="label" class="center">部门职能</div>
            <div class="">
              {{ detail.remarks }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">部门性质</div>
            <div class="">
              <DataDictFinder
                dictType="orgProperty"
                :dictValue="detail.property"
              />
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">分管领导</div>
            <div class="">
              {{ detail.chargerName ? detail.chargerName : "--" }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">负责人</div>
            <div class="">
              {{ detail.master2Name ? detail.master2Name : "--" }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">负责人（副）</div>
            <div class="">
              {{ detail.master1Name ? detail.master1Name : "--" }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">定编人数</div>
            <div class="">
              {{ detail.staffing }}
            </div>
          </a-descriptions-item>

          <a-descriptions-item>
            <div slot="label" class="center">在编人数</div>
            <div class="">
              {{
                Array.isArray(detail.employees) ? detail.employees.length : ""
              }}人
            </div>
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <UserTree
        :deptId="detail.id"
        :master2Obj="master2Obj"
        :instituteVoList="instituteVoList"
        :list="otherList"
      />
    </a-card>
  </div>
</template>

<script>
import UserTree from "./components/user-tree.vue";
import { fetchDetailTree } from "@/api/setting/organization";

export default {
  components: {
    UserTree,
  },

  data() {
    return {
      detail: {},
    };
  },

  computed: {
    master2Obj() {
      if (Array.isArray(this.detail.leaders)) {
        const obj = this.detail.leaders.find(
          (item) => item.userId === this.detail.master2
        );
        return obj ? obj : {};
      } else {
        return {};
      }
    },
    leaders() {
      if (Array.isArray(this.detail.leaders)) {
        return this.detail.leaders.filter(
          (item) => item.userId !== this.detail.master2
        );
      } else {
        return [];
      }
    },
    otherList() {
      let { master1, master2, charger, employees } = this.detail;
      master1 = master1 ?? "";

      charger = charger ?? "";
      if (Array.isArray(employees)) {
        const list = [...employees.filter((item) => item.userId !== master2)];
        let otherList = list.filter((item) => {
          return (
            master1.indexOf(item.userId) === -1 ||
            charger.indexOf(item.userId) === -1
          );
        });
        const users = this.leaders.map((item) => {
          if (master1.indexOf(item.userId) > -1) {
            item.job = "副所长";
          }
          if (charger.indexOf(item.userId) > -1) {
            item.job = "分管领导";
          }
          return item;
        });
        return [...users, ...otherList];
      } else {
        return [];
      }
    },

    instituteVoList() {
      return Array.isArray(this.detail.instituteVoList)
        ? this.detail.instituteVoList
        : [];
    },
  },

  mounted() {
    const { id = "" } = this.$route.query;
    if (!id) return;
    fetchDetailTree({
      uuid: id,
    }).then((res) => {
      if (res) {
        this.detail = res;
      }
    });
  },
};
</script>


<style scoped>
.container {
  position: relative;
}

.info {
  position: absolute;
  top: 8px;
  left: 8px;
}
</style>